import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

class ProjectsRollTemplate extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <div className="columns is-multiline is-gapless">
        {posts &&
          posts.map(({ node: post }) => (
            <Link
              className="is-parent column is-full-mobile is-half-tablet is-half-desktop is-one-third-widescreen"
              key={post.id}
              to={post.fields.slug}
            >
              <article
                style={{
                  position: "relative",
                  minHeight: "450px",
                }}
              >
                {post.frontmatter.featuredimage ? (
                  <PreviewCompatibleImage
                    style={{
                      height: "100%",
                      width: "100%",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      right: "0",
                      bottom: "0",
                    }}
                    imageInfo={{
                      image: post.frontmatter.featuredimage,
                      alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                      width:
                        post.frontmatter.featuredimage.childImageSharp
                          .gatsbyImageData.width,
                      height:
                        post.frontmatter.featuredimage.childImageSharp
                          .gatsbyImageData.height,
                    }}
                  />
                ) : null}
                <header className="project-roll-item-container">
                  <p className="post-meta">
                    <div className="title is-size-4" style={{ color: "white" }}>
                      {post.frontmatter.title}
                    </div>
                  </p>
                  <p
                    className="subtitle is-size-5 is-block"
                    style={{ color: "white" }}
                  >
                    {(post.frontmatter.tags ?? []).join(", ")}
                  </p>
                </header>
              </article>
            </Link>
          ))}
      </div>
    );
  }
}

ProjectRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function ProjectRoll() {
  return (
    <StaticQuery
      query={graphql`
        query ProjectRollQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "project-post" } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  tags
                  templateKey
                  date(formatString: "MMMM DD, YYYY")
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(
                        width: 720
                        quality: 100
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => (
        <ProjectsRollTemplate data={data} count={count} />
      )}
    />
  );
}
